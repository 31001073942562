<template>
  <section class="campaign-setup-creatives">
    <div class="tw-mb-layout-1 tw-flex tw-mt-0">
      <transition name="fade-in-linear" mode="out-in">
        <app-button
          v-if="campaign.assets_folder_url"
          class="tw-bg-jb-indigo"
          size="micro"
          @click="openInBox">
          Open in Box
        </app-button>
      </transition>
    </div>
    <app-box-explorer
      v-if="campaign"
      :show-skeletons="loading"
      :folder-id="campaign.assets_folder_id || ''"
      :access-token="campaign.assets_storage_token || ''"
      :can-download="true" />
  </section>
</template>

<script>
import AppBoxExplorer from '@/components/AppBoxExplorer';
import AppButton from '@/components/AppButton.vue';

export default {
  name: 'CampaignSetupCreatives',
  components: { AppBoxExplorer, AppButton },
  data() {
    return {
      loading: true,
      tokenTime: 0
    };
  },
  computed: {
    campaignId() {
      return this.$route.params.id;
    },
    campaign() {
      return this.$store.getters.campaign(this.campaignId);
    }
  },
  watch: {
    campaignId: {
      immediate: true,
      handler(newVal, oldVal) {
        this.refreshToken(oldVal !== undefined);
      }
    }
  },
  methods: {
    // Token expires in 1hr, so reload box just before to keep it fresh
    shouldRefreshToken(now, force) {
      return now - this.tokenTime >= 60 * 59 * 1000 || force;
    },
    refreshToken(force = false) {
      const now = new Date().getTime();
      if (this.shouldRefreshToken(now, force)) {
        this.loading = true;
        this.$api.campaigns
          .single({
            orgId: this.$store.getters.organization.id,
            campaignId: this.campaignId,
            force: true,
            storeAction: 'fetchCampaign'
          })
          .then(() => {
            this.tokenTime = now;
          })
          .finally(() => {
            this.loading = false;
          });
      }
    },
    openInBox() {
      window.open(this.campaign.assets_folder_url, '_blank').focus();
    }
  }
};
</script>

<style lang="scss">
.campaign-setup-creatives {
  @include stage-layout;

  @apply tw-mt-0;

  .app-box-explorer {
    height: 600px;
  }
}
</style>
