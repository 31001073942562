<template>
  <div class="app-box-explorer">
    <app-box-explorer-skeleton v-if="!contentExplorer || !loaded" />
    <div :class="{ hidden: !contentExplorer || !loaded }" class="app-box-explorer__box">
      <div v-once :id="id" class="app-box-explorer__box-explorer" />
    </div>
  </div>
</template>

<script>
import AppBoxExplorerSkeleton from '@/components/skeletons/app/AppBoxExplorerSkeleton';
import _debounce from 'lodash/debounce';

const ExplorerJs = () => import('box-ui-elements/dist/explorer.js');
const ExplorerCss = () => import('box-ui-elements/dist/explorer.css');

const loadImports = () => {
  return Promise.all([ExplorerJs(), ExplorerCss()]);
};

export default {
  name: 'AppBoxExplorer',
  components: { AppBoxExplorerSkeleton },
  props: {
    showSkeletons: {
      type: Boolean,
      default: false
    },
    folderId: {
      type: String,
      required: true
    },
    accessToken: {
      type: String,
      required: true
    },
    canDownload: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      loaded: false,
      contentExplorer: undefined,
      initializeDebounce: _debounce(this.initializeBox, 64, {
        trailing: true
      })
    };
  },
  computed: {
    id() {
      return `box-${this._uid}`;
    }
  },
  watch: {
    $props: {
      handler() {
        this.destroyBox();
        this.initializeDebounce();
      },
      immediate: true,
      deep: true
    }
  },
  destroyed() {
    this.destroyBox();
  },
  methods: {
    async initializeBox() {
      if (!this.showSkeletons) {
        await loadImports();
        this.contentExplorer = new Box.ContentExplorer();
        this.contentExplorer.addListener('navigate', () => setTimeout(() => { this.loaded = true; }, 250));
        this.contentExplorer.show(this.folderId, this.accessToken, {
          container: '#' + this.id,
          canDownload: this.canDownload,
          autoFocus: true
        });
      }
    },
    destroyBox() {
      if (this.contentExplorer) {
        this.contentExplorer.clearCache();
        this.contentExplorer.hide();
        this.contentExplorer = undefined;
        this.loaded = false;
      }
    }
  }
};
</script>

<style lang="scss">
.app-box-explorer {
  position: relative;

  &__box,
  &__box-explorer {
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
  }

  .hidden {
    visibility: hidden;
  }

  .be {
    .be-logo {
      display: none;
    }

    .be-header {
      background: $--clb-color-secondary__light;
      border: 1px solid $--clb-border-color-base;
      border-radius: $--clb-border-radius;
    }

    input {
      box-shadow: none !important;

      &:focus,
      &:active,
      &:hover {
        border-color: $--clb-color-primary !important;
        color: $--clb-color-primary !important;
      }
    }

    .be-empty div {
      display: none;
    }

    .bcpr-name {
      max-width: 275px;
    }

    .bcpr-btns .btn-plain.bcpr-btn {
      margin: 0;

      &:first-child {
        display: none;
      }
    }
  }

  @media (min-width: $--xs) {
    .be {
      .bcpr-name {
        max-width: none;
      }

      .bcpr-btns .btn-plain.bcpr-btn {
        margin: 0 10px 0 0;
      }
    }
  }
}
</style>
