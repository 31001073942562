<template>
  <div v-once class="app-box-explorer-skeleton">
    <skeleton-box
      width="100%"
      height="70px"
      class="app-box-explorer-skeleton__header" />
    <div v-for="i in 4" :key="i" class="app-box-explorer-skeleton__body">
      <div class="app-box-explorer-skeleton__body-item">
        <skeleton-box
          :width="i === 1? '150px': `${250 + (-10 * (i % 2? -i: i))}px`"
          height="15px" />
      </div>
      <div class="app-box-explorer-skeleton__body-item hidden-sm-and-down">
        <skeleton-box
          v-if="i > 1"
          :width="`${150 + (-5 * (i % 2? i: -i))}px`"
          height="15px" />
      </div>
      <div class="app-box-explorer-skeleton__body-right" />
    </div>
  </div>
</template>

<script>
import SkeletonBox from '@/components/skeletons/SkeletonBox';
export default {
  name: 'AppBoxExplorerSkeleton',
  components: { SkeletonBox }
};
</script>

<style lang="scss">
.app-box-explorer-skeleton {
  &__body {
    height: 50px;
    display: flex;
    border-bottom: 1px solid $--clb-skeleton-color;

    &-item {
      margin: auto auto auto 25px;
      display: flex;
      flex: 1 1 350px;

      svg {
        margin: 0;
      }
    }

    &-right {
      flex: 0 0 140px;
    }
  }
}
</style>
